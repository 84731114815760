/* _thirdparties.css :
  Contains all the global styling needed for 3rd parties module
*/

/* Stripe - Used for IBAN Input */
.StripeElement,
.StripeElement--webkit-autofill {
  width: 100%;
  height: 42px;
  padding-left: 14px;
  padding-right: 28px;
  border-radius: 6px;
  border: var(--border);
  border-color: var(--clr-tertiary-2);
  background: var(--white);
  font-size: 16px;
  color: var(--black);
  font-family: inherit;
}

.StripeElement {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Algolia */
/** Random class when using `QueryRuleCustomData` with no way to style it */
.ais-QueryRuleCustomData {
  width: 100%;
}
