/* _forms.css :
  Contains all the global styling related to the forms and their components: form, input, select, fieldset, placeholders
*/

select {
  border: none;
  width: 100%;
  height: 45px;
  padding-left: 14px;
  padding-right: 28px;
  background: var(--white);
  cursor: pointer;
  border-radius: 2px;
  line-height: normal;
  font-size: 16px;
  border-radius: var(--rd-2);
  border: var(--border);
}

.input-select {
  height: fit-content;
}

/* hide input native clear button */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

input::placeholder {
  color: var(--clr-grey-1);
}
