/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: var(--clr-tertiary-1);
  position: fixed;
  z-index: var(--zindex-fixed);
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px var(--clr-tertiary-1), 0 0 5px var(--clr-tertiary-1);
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: var(--zindex-fixed);
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  display: none;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner {
  display: none;
}

.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
