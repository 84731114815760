.swiper {
  width: 100%;
}

/* Remove useless z-indexes https://github.com/nolimits4web/swiper/discussions/5988 */
.swiper {
  z-index: initial !important;
}

.swiper-wrapper {
  z-index: initial !important;
}

.swiper-button-prev,
.swiper-button-next {
  z-index: initial !important;
}

/* Remove useless stacking context creating properties */
.swiper-backface-hidden .swiper-slide {
  transform: initial !important;
  backface-visibility: initial !important;
}

.lf-carousel .swiper-button-prev.swiper-button-disabled,
.lf-carousel .swiper-button-next.swiper-button-disabled {
  background-color: theme('colors.yellow.600');
  opacity: 0.3;
}

.lf-carousel.lf-carousel-v2 .swiper-button-prev.swiper-button-disabled,
.lf-carousel.lf-carousel-v2 .swiper-button-next.swiper-button-disabled {
  display: none;
}

.lf-carousel.lf-carousel-v2 .swiper-button-prev,
.lf-carousel.lf-carousel-v2 .swiper-button-next {
  width: 52px;
  height: 52px;
}

.lf-carousel.lf-carousel-v2 .swiper-button-prev {
  position: absolute;
  left: -24px;
}

.lf-carousel.lf-carousel-v2 .swiper-button-next {
  position: absolute;
  right: -24px;
}

.lf-carousel.lf-carousel-v2 .swiper-button-prev:after,
.lf-carousel.lf-carousel-v2 .swiper-button-next:after {
  font-size: 16px;
}

.lf-carousel .swiper-button-prev,
.lf-carousel .swiper-button-next {
  box-shadow: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  appearance: none;
  background-color: theme('colors.yellow.600');
  color: theme('colors.black.1000');
  transition: background-color 0.5s;
}

.lf-carousel .swiper-button-prev:hover,
.lf-carousel .swiper-button-next:hover {
  background-color: theme('colors.yellow.400');
}

.lf-carousel-button-lg .swiper-button-prev,
.lf-carousel-button-lg .swiper-button-next {
  width: 52px;
  height: 52px;
}

.lf-carousel .swiper-button-prev:after,
.lf-carousel .swiper-button-next:after {
  font-size: 10px;
}

.lf-carousel-button-lg .swiper-button-prev:after,
.lf-carousel-button-lg .swiper-button-next:after {
  font-size: 18px;
}

div.swiper-slide {
  height: auto;
}

.lf-slide-size-auto div.swiper-slide {
  width: auto;
}

span.swiper-pagination-bullet {
  background-color: theme('colors.white');
  border: 1px solid theme('colors.black.1000');
  opacity: 1;
}

span.swiper-pagination-bullet-active {
  opacity: 1;
  background: theme('colors.black.1000');
  background-color: theme('colors.black.1000');
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: theme('zIndex.fixed');
  bottom: 0;
}

@media (max-width: 800px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}

/** TODO: remove after using the compound component pattern in the carousel */
@media (min-width: 801px) {
  .carousel-and-card-wrapper :global(.editorial-card) {
    flex-shrink: 0;
    width: calc(25% - var(--space-5));
    min-width: 250px;
    margin-right: var(--space-5);
  }
}

@media (max-width: 800px) {
  .carousel-and-card-wrapper :global(.editorial-card) {
    height: 100%;
    max-width: 250px;
  }

  .swiper-gradient :global(.swiper:after) {
    display: none;
  }
}
