/* _reset.css :
  Contains all the global styling to reset or harmonize browser inconsistencies
*/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  min-height: 100%;
}

body {
  font-family: var(--font-family-body);
  font-style: normal;
  font-weight: 300;
  max-width: 1920px;
  margin: 0 auto;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  touch-action: manipulation;
  color: inherit;
}

ul li {
  list-style: none;
}
