/* _typography.css :
  Contains all the global styling related to any typographic element excluding the links: titles, paragraphs, lists, etc..
*/

/* Titles */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

h1 {
  font-family: var(--font-family-1);
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-bold);
  line-height: 1.2;
}

section h1 {
  font-size: var(--font-size-4);
}

h2 {
  font-family: var(--font-family-1);
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-bold);
}

h3 {
  font-family: var(--font-family-2);
  font-size: var(--text-size-h3);
  color: var(--black);
  font-weight: var(--font-weight-light);
}

h4 {
  font-family: var(--font-family-2);
  font-size: var(--text-size-h4);
}

h5 {
  font-family: var(--font-family-2);
  font-size: var(--text-size-h5);
  color: var(--black);
}

h6 {
  font-family: var(--font-family-2);
  font-size: var(--text-size-h6);
}

.hxrt {
  font-family: var(--font-family-2);
  font-size: var(--font-size-2);
  line-height: 1.1;
}

.hxr {
  font-family: var(--font-family-2);
  font-size: var(--font-size-2);
}

.hxru {
  font-family: var(--font-family-2);
  font-size: var(--font-size-2);
  text-decoration: underline;
}

.hxlt {
  font-family: var(--font-family-2);
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-light);
  color: var(--clr-grey-1);
  line-height: 1.1;
}

.hzb {
  font-family: var(--font-family-2);
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-bold);
  line-height: 1.1;
}

.hxb {
  font-family: var(--font-family-2);
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-bold);
  line-height: 1.1;
}

.hxm {
  font-family: var(--font-family-2);
  font-size: var(--font-size-2);
  font-style: normal;
  font-weight: var(--font-weight-base);
}

.h4b {
  font-family: var(--font-family-2);
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-bold);
  line-height: 1.1;
}

.h4r {
  font-family: var(--font-family-2);
  font-size: var(--font-size-3);
  line-height: 1.1;
}

.hzr {
  font-family: var(--font-family-2);
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-light);
  line-height: 1.1;
}

.title-xs {
  font-family: var(--font-family-1);
  font-size: var(--font-size-2-5);
  font-weight: var(--font-weight-bold);
  line-height: 1.1;
}

.title-xxs {
  font-family: var(--font-family-1);
  font-size: var(--font-size-1-5);
  font-weight: var(--font-weight-bold);
  line-height: 1.1;
}

.hzr2 {
  font-family: var(--font-family-2);
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-base);
  line-height: 1.1;
}

.hzr2d {
  font-family: var(--font-family-1);
  font-size: var(--font-size-1-5);
  font-weight: var(--font-weight-base);
}

.h6 {
  font-family: var(--font-family-1);
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-bold);
}

.h6b {
  font-weight: var(--font-weight-black);
  font-family: var(--font-family-1);
  font-size: var(--font-size-1-5);
}

.accent-title {
  font-family: var(--font-family-2);
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-bold);
  font-style: italic;
  line-height: 1.2;
  text-transform: uppercase;
}

.accent-title-s {
  font-family: var(--font-family-2);
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-base);
  line-height: var(--font-size-2-5);
}

.catchy-title,
.catchy-title-main,
.catchy-title-l,
.catchy-title-s {
  font-family: var(--font-family-1);
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-bold);
  line-height: 1.25;
}

.catchy-title-l {
  font-size: var(--font-size-5);
}

.catchy-title-s {
  font-size: var(--font-size-3);
}
.catchy-title .title-icon {
  vertical-align: -14px;
  margin-left: var(--space-3);
}

@media (min-width: 801px) {
  .h4b {
    font-size: var(--font-size-3);
  }
}

@media (max-width: 800px) {
  h1 {
    font-size: var(--font-size-4);
  }

  section h1 {
    font-size: var(--font-size-3);
  }
  .catchy-title,
  .catchy-title-l,
  .catchy-title-main {
    font-size: var(--font-size-3);
  }
  .catchy-title-main {
    display: inline-flex;
    text-align: center;
  }
  .catchy-title-s {
    font-size: var(--font-size-2-5);
  }
}

/* Texts, Paragraphs */
p {
  font-family: var(--font-family-2);
  font-size: var(--font-size-2);
  color: var(--black);
  line-height: 20px;
}

.text-base {
  font-family: var(--font-family-2);
  font-size: var(--font-size-2);
}

.text-secondary {
  color: var(--clr-grey-4);
}

.text-error {
  color: var(--clr-danger);
}

/* Lists */
:root {
  --bullet-color: var(--clr-tertiary-1);
}

.ordered-list {
  --olist-bullet-size: 30px;
  --olist-indent: var(--olist-bullet-size) + 1em;
  list-style: none;
  counter-reset: my-counter;
}
.ordered-list > li {
  position: relative;
  padding-left: calc(var(--olist-indent));
  padding-bottom: var(--space-4);
  counter-increment: my-counter;
}
.ordered-list > li::before {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--olist-bullet-size);
  height: var(--olist-bullet-size);
  border-radius: 50%;
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-bold);
  line-height: var(--olist-bullet-size);
  text-align: center;
  background: var(--clr-primary-1);
  content: counter(my-counter);
}

/* Avoid mobile text auto resize */
:root {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
