/* _layout.css :
  Contains all the global styling related to the layout: grid, main background color, etc ...
*/

#__next,
body {
  height: 100%;
  min-height: 100vh;
}

.header-limited-width-container {
  max-width: var(--header-layout-max-width);
  margin-left: auto;
  margin-right: auto;
}

.limited-width-container {
  max-width: var(--layout-max-width);
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 800px) {
  .header-shadow {
    box-shadow: 0px 4px 8px 0px rgba(104, 98, 98, 0.05);
  }

  .header-limited-width-container,
  .limited-width-container {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 801px) and (max-width: 1248px) {
  .limited-width-container {
    margin-left: var(--layout-margin);
    margin-right: var(--layout-margin);
  }
}

@media (max-width: 1488px) {
  .header-limited-width-container {
    margin-left: var(--layout-margin);
    margin-right: var(--layout-margin);
  }
}
