/* _utils.css :
  Contains all the small classes that helps us quickly add repetitive css rules.
  Most of them should be removed once we introduce an utility-first CSS framework like Tailwind ;)
*/

.no-overflow {
  overflow: hidden;
}

.bold {
  font-weight: var(--font-weight-bold);
}

.capitalize-first-letter::first-letter {
  text-transform: capitalize;
}

.display-none {
  display: none;
}

.pointer {
  cursor: pointer;
}

.line-through {
  text-decoration: line-through;
}

.capitalize {
  text-transform: capitalize;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

@media (min-width: 801px) {
  .mobile-only {
    display: none !important;
  }
}

@media (max-width: 800px) {
  .desktop-only {
    display: none !important;
  }
}
